import { AuthToken, marshalAuthToken } from '../utils/authToken';
import { IdPs } from '../constants/providers';
import { AppURL } from '../constants/urls';
import { AppConfig } from '../types/app';
import { useIntl } from 'react-intl';
import React, { SyntheticEvent, useState } from 'react';
import {
  Alert,
  Button,
  Container,
  Form,
  Header,
  SpaceBetween,
  TextContent,
} from '@amzn/awsui-components-react';
import accountlinkConsent from '../i18n/accountlinkConsent.messages';
import styles from './CardContainer.module.css';
import { emitAccountLinkingRUMEvents } from '../services/emitAccountLinkingRUMEvent';
import { localStorageAdapter } from '../services/storage';
import { useLocation } from 'react-router-dom';
import { signoutUrlMap } from '../constants/signoutUrls';
import jwtDecode from 'jwt-decode';
import Error from '../pages/Error';
import { accountlinkfaqPageDetails } from '../i18n/accountLinkFaqContent.messages';
import usePageTitle from '../hooks/usePageTitle';
import { AccountLinkContainer } from './AccountLinkContainer';
import { AccountLinkICantButton } from './AccountLinkICantButton';
import { LAST_USED_GANDALF_TOKEN, LAST_USED_STATE } from '../constants/auth';
import CantValidateAccount from './CantValidateAccount';

export function getLogoutUrl(config: AppConfig) {
  const redirectUrl = `${window.location.origin}${AppURL.SignOutToAllowSignInFlowRedirect}`;
  return `https://${config.accountLinkAuthGandalfDomain}/logout?client_id=${config.accountLinkAuthClientID}&logout_uri=${redirectUrl}&response_type=code`;
}

const SignOutToAllowSignIn = ({
  config,
}: {
  config: AppConfig;
}): JSX.Element => {
  const { formatMessage } = useIntl();
  const [showCant, setShowCant] = useState(false);
  const location = useLocation();
  usePageTitle(formatMessage(accountlinkConsent.signOutToAllowSignInButton));

  var authToken: AuthToken | null = null;

  const hState = location.state;

  if (hState && (hState as any).authToken) {
    authToken = (hState as any).authToken;
  }

  const initiateLwaLogoutFlow = (authToken: AuthToken | null) => {
    const marshaledGandalfAuthToken: string = marshalAuthToken(authToken!);
    localStorageAdapter.setItem(
      LAST_USED_GANDALF_TOKEN,
      marshaledGandalfAuthToken
    );

    localStorageAdapter.setItem(LAST_USED_STATE, authToken?.nonce!);

    //Call the cognito logout endpoint
    const logoutUrl = getLogoutUrl(config);
    window.location.href = logoutUrl + `&state=${authToken?.nonce}`;
  };

  if (authToken) {
    //decode the authToken to get the jwt token
    const authTokenDecoded: any = jwtDecode(authToken?.jwtToken ?? '');
    const userEmail = authTokenDecoded['email'];

    const handleFormSubmit = (event: SyntheticEvent) => {
      event.preventDefault();

      emitAccountLinkingRUMEvents(
        authToken?.jwtToken ?? '',
        'signOutToAllowSignInStepOne'
      );
      window.open(
        signoutUrlMap.get(IdPs.LoginWithAmazon),
        '_blank',
        'noreferrer'
      );

      initiateLwaLogoutFlow(authToken);
    };

    return (
      <>
        <AccountLinkContainer config={config}>
          {showCant ? (
            <CantValidateAccount
              authToken={authToken}
              config={config}
              onDismiss={() => setShowCant(false)}
            />
          ) : (
            <Container
              header={
                <>
                  <Header
                    className={styles.header}
                    variant="h2"
                    headingTagOverride="h1"
                  >
                    {formatMessage(
                      accountlinkConsent.signOutToAllowSignInHeaderStepOne
                    )}
                  </Header>
                </>
              }
            >
              <form onSubmit={handleFormSubmit} noValidate>
                <Form
                  actions={
                    <>
                      <SpaceBetween direction="horizontal" size="xs">
                        <AccountLinkICantButton
                          authToken={authToken}
                          onCantClicked={() => setShowCant(true)}
                          config={config}
                        />
                        <>
                          <Button
                            variant="primary"
                            formAction="submit"
                            data-testid="email-submit"
                          >
                            {formatMessage(
                              accountlinkConsent.signOutToAllowSignInButton
                            )}
                          </Button>
                        </>
                      </SpaceBetween>
                    </>
                  }
                >
                  <SpaceBetween direction="vertical" size="s">
                    <TextContent>
                      {formatMessage(
                        accountlinkConsent.signOutToAllowSignInContent,
                        {
                          originalUserEmail: userEmail,
                          b: (str) => <b>{str}</b>,
                        }
                      )}{' '}
                      <a
                        href={`${AppURL.AccountLinkFAQ}`}
                        target="_blank"
                        data-testid="account-link-help-link"
                        rel="noopener noreferrer"
                        onClick={() => {
                          emitAccountLinkingRUMEvents(
                            authToken?.jwtToken!,
                            'confirm_exisiting_profile_learn_more_button'
                          );
                        }}
                      >
                        {formatMessage(
                          accountlinkfaqPageDetails.learnMoreAboutProfileLinking
                        )}
                      </a>
                    </TextContent>

                    <>
                      {' '}
                      <Alert type="info">
                        <TextContent>
                          {formatMessage(
                            accountlinkConsent.returnToThisTabToValidate,
                            {
                              b: (str) => <b>{str}</b>,
                            }
                          )}
                        </TextContent>
                      </Alert>
                    </>
                  </SpaceBetween>
                </Form>
              </form>
            </Container>
          )}
        </AccountLinkContainer>
      </>
    );
  } else {
    return <Error config={config} details="Auth Token Not Found" />;
  }
};

export default SignOutToAllowSignIn;
