import React from 'react';
import { LiveComponent } from '@amzn/aws-tc-xb-xps';
import { PostAuthComponentParams } from '../../types/auth';
import { AppConfig } from '../../types/app';

function ProfileAndPreferences({
  config,
  onContinue,
  user,
}: PostAuthComponentParams) {
  if (
    config.enableProfilePreferences !== 'true' ||
    user.profile_completion_required !== 'true' ||
    !shouldRenderProfileAndPreferencesForClient(config)
  ) {
    onContinue();
    return <></>;
  }
  return (
    <LiveComponent
      experienceProviderName="userorg"
      componentName="UserProfileEditAll"
      payload={JSON.stringify({
        token: user?.jwtToken,
      })}
    />
  );
}

const shouldRenderProfileAndPreferencesForClient = (
  config: AppConfig
): boolean => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const client = urlSearchParams.get('client_id');

  if (
    config.userProfileRequiredForClients === undefined ||
    config.userProfileRequiredForClients.length === 0
  )
    return false;

  return config.userProfileRequiredForClients.includes(client!);
};

export default ProfileAndPreferences;
